import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },
});

const MiniAppFailurePage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <h3 style={{ textAlign: 'center' }}>
        Server not reachable. Please scan QR again or Call waitress for
        assistance
      </h3>
    </Box>
  );
};

export default MiniAppFailurePage;
