import noimage from '@assets/images/noimage.png';

export const getImageAvatar = (url) => {
  if (url) {
    return url + '?width=40';
  } else {
    return noimage;
  }
};
export const getImageMenuCategory = (url) => {
  if (url) {
    return url;
  } else {
    return noimage;
  }
};

export const getImageThumnailList = (url) => {
  if (url) {
    return url + '?width=90&height=90';
  } else {
    return noimage;
  }
};

export const getImageThumnailGrid = (url, width, height) => {
  if (url) {
    return url + '?width=' + width + '&height=' + height;
  } else {
    return noimage;
  }
};

export const getImageFullPage = (url) => {
  if (url) {
    return url;
  } else {
    return noimage;
  }
};
