import React from 'react';
import { Grid, Button } from '@mui/material';
import ReDialog from '@components/ReDialog';
import { WarningRounded } from '@mui/icons-material';

const WeatherWarning = ({
  actionClick,
  theme
}) => {
  return (
    <ReDialog
      closeModal={() => { }}
      title={
        <div>
          <WarningRounded
            style={{ color: theme.primary_font_color, fontSize: '3rem' }}
          />
          <p> Order Preparation </p>
        </div>
      }
      content={
        <Grid container direction="row" style={{ textAlign: 'center' }}>
          <Grid item sx={{ width: '100%', textAlign: 'center', marginBottom: 5 }}>
            <p>Order will be ready in 30-40 mins - proceed ?</p>
          </Grid>
          <Grid item sx={{ width: '50%' }}>
            <Button
              onClick={() => {
                actionClick(false);
              }}
              sx={{
                width: '90%',
                height: 48,
                fontSize: 16,
                background: theme.primary_color || theme.primaryThemeColor,
                color: theme.secondary_font_color || theme.secondaryFontColor,
                "&:hover": {
                  background: theme.primary_color || theme.primaryThemeColor,
                }
              }}
              variant="contained">
              No
            </Button>
          </Grid>
          <Grid item sx={{ width: '50%' }}>
            <Button
              onClick={() => {
                actionClick(true);
              }}
              sx={{
                width: '90%',
                height: 48,
                fontSize: 16,
                background: theme.primary_color || theme.primaryThemeColor,
                color: theme.secondary_font_color || theme.secondaryFontColor,
                "&:hover": {
                  background: theme.primary_color || theme.primaryThemeColor,
                }
              }}
              variant="contained">
              Yes
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default WeatherWarning;
