import { useEffect, useState } from "react";
import { useStoreAppColor } from "../state/useStoreAppColor";
import { useStoreCategory } from "../state/useStoreCategory";
import { useStoreCategoryProducts } from "../state/useStoreCategoryProducts";
import { useStoreFeedback } from "../state/useStoreFeedback";
import { useStoreNutriGrade } from "../state/useStoreNutriGrade";
import { useStoreOrderCategory } from "../state/useStoreOrderCategory";
import { useStoreOutlet } from "../state/useStoreOutlet";
import { useStoreSettings } from "../state/useStoreSettings";
import { useStoreSpreadProduct } from "../state/useStoreSpreadProduct";

export default function useHydration() {
    const [hydrated, setHydrated] = useState(true);
    const [kode, setKode] = useState('000');
    //ambil semua hydrated dari semua state zustand
    const { _hasHydratedColor, kodeColor } = useStoreAppColor()
    const { _hasHydratedCategory, kodeCategory } = useStoreCategory()
    const { _hasHydratedCategoryProd,  kodeCategoryProd } = useStoreCategoryProducts()
    const { _hasHydratedFeedback, kodeFeedback } = useStoreFeedback()
    const { _hasHydratedNutri, kodeNutri } = useStoreNutriGrade()
    const { _hasHydratedOrderCategory, kodeOrderCategory } = useStoreOrderCategory()
    const { _hasHydratedOutlet, kodeOutlet } = useStoreOutlet()
    const { _hasHydratedSetting, kodeSetting } = useStoreSettings()
    const { _hasHydratedSpreadCategory, kodeSpreadCategory } = useStoreSpreadProduct()
    
    const checkHydrated = () => {
        console.log(
            _hasHydratedColor , 
            _hasHydratedCategory , 
            _hasHydratedCategoryProd ,
            _hasHydratedFeedback ,
            _hasHydratedNutri ,
            _hasHydratedOrderCategory ,
            _hasHydratedOutlet ,
            _hasHydratedSetting ,
            _hasHydratedSpreadCategory);
        if(
            !_hasHydratedColor || 
            !_hasHydratedCategory || 
            !_hasHydratedCategoryProd ||
            !_hasHydratedFeedback ||
            !_hasHydratedNutri ||
            !_hasHydratedOrderCategory ||
            !_hasHydratedOutlet ||
            !_hasHydratedSetting ||
            !_hasHydratedSpreadCategory
        ) {
            console.log('hydration gagal-------');
            setHydrated(false)
        } 
        if (!_hasHydratedColor) {
            setKode(kodeColor);
        } else if (!_hasHydratedCategory) {
            setKode(kodeCategory);
        } else if (!_hasHydratedCategoryProd) {
            setKode(kodeCategoryProd);
        } else if (!_hasHydratedFeedback) {
            setKode(kodeFeedback);
        } else if (!_hasHydratedNutri) {
            setKode(kodeNutri);
        } else if (!_hasHydratedOrderCategory) {
            setKode(kodeOrderCategory);
        } else if (!_hasHydratedOutlet) {
            setKode(kodeOutlet);
        } else if (!_hasHydratedSetting) {
            setKode(kodeSetting);
        } else if (!_hasHydratedSpreadCategory) {
            setKode(kodeSpreadCategory);
        }
    }
    useEffect(() => {
        checkHydrated()

    }, [])

    return [hydrated, kode, checkHydrated]
}
