import { create } from "zustand"
import localStorageNames from "@data/localStorageNames";
import { useStoreFirstCredential } from "./useStoreFirstCredential";
import countObjectValues from '@functions/countObjectValues';
import { sumTotalPriceWithPrepItem } from '@functions/sumTotalPriceWithPrepItem';
import { useStoreOrderCategory } from "./useStoreOrderCategory";
import { persist } from 'zustand/middleware'


export const useStoreCartItems = create(persist((set, get) => ({
    cartItems: [],
    setCartItems: (newItems) => {
        set({ cartItems: newItems })
    },
    setCartItemLocalStorage: (newData) => {
        if (useStoreFirstCredential.getState().isLoggedIn()) {
            set({ cartItems: newData })
        }
    },
    getCartItems: () => {
        return get().cartItems
    },
    clearCart: () => {
        set({ cartItems: [] })
    },
    getBadgeCount: () => {
        if (get().getCartItems().length === 0) {
            return 0
        }
        return countObjectValues(get().getCartItems(), 'quantity');
    },
    totalQty: () => {
        return countObjectValues(get().getCartItems(), 'quantity');
    },
    totalPrice: () => {
        let subTotal = 0;
        get().getCartItems().forEach((item) => {
            subTotal += sumTotalPriceWithPrepItem(
                item,
                item.quantity,
                useStoreOrderCategory.getState().getSellBandId(),
            );
        });
        return subTotal;
    },
    totalPriceWithCharge: (serviceCharge) => {
        const totalAmount = serviceCharge.reduce((sum, item) => {
            return sum + item.amount
        }, 0);
        return (get().totalQty() * totalAmount) + get().totalPrice()
    },
    changeCartItemQuantity: (item, quantity) => {
        let items = [];
        let itemIndex = -1;
        items = get().getCartItems();
        itemIndex = items.findIndex(
            (x) =>
                x.uuid_product === item.uuid_product && x.timestamp === item.timestamp,
        );

        if (itemIndex !== -1) {
            items[itemIndex].quantity = quantity;
            get().setCartItems(items);
        }
    },
    isChooseRewards: () => {
        let parentItems = get().getCartItems().filter((x) => x.promoFrom === undefined);
        const lastParentItem = parentItems[parentItems.length - 1];
        let rewardItems = get().getCartItems().filter(
            (x) => x.promoFrom === lastParentItem.timestamp,
        );
        if (lastParentItem.quantity === rewardItems.length) {
            return true;
        } else {
            return false;
        }
    },
    removeItemInCart: (item) => {
        let items = get().getCartItems();
        items = items.filter((x) => x.timestamp !== item.timestamp);
        items = items.filter((oldItem) => oldItem.promoFrom !== item.timestamp);
        set({ cartItems: items })
    }
}),
    {
        name: localStorageNames.CART_ITEMS,
    }
)
)