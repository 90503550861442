import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import noimage from '@assets/images/noimage.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  generalImage: {
    objectFit: 'contain',
    display: 'block !important'
  },
});

const ReLazyImage = ({ index, url, style }) => {
  const classes = useStyles();
  return (
    <LazyLoadImage
      wrapperClassName={classes.generalImage}
      style={{ width: "100%", objectFit: 'cover', ...style }}
      alt=""
      effect="blur"
      src={url ? `${url}` : noimage}
      id={`img-${index}`}
    />
  );
};

export default ReLazyImage;
