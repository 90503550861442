import { useEffect, useState } from 'react'
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from "@styles/theme"
const useStyles = makeStyles((theme) => ({
  linearprogress: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: 20,
    zIndex: 99,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props) => (props.mode ? '#eee' : 'black'),
  },
  colorPrimary: {
    backgroundColor: (props) => (props.mode ? '#020202' : '#eee'),
  },
}));

export default function MuiLinearProgress() {
  const [progress, setProgress] = useState(0);
  const classes = useStyles();


  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        return Math.min(oldProgress + 2.5, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LinearProgress
      className={classes.linearprogress}
      variant="determinate"
      value={progress}
      classes={{ bar: classes.bar, colorPrimary: classes.colorPrimary }}
      sx={{
        height: '.7rem',
        backgroundColor: '#e0e0e0',
        '& .MuiLinearProgress-bar': {
          borderRadius: 5,
          backgroundColor: '#000000',  // Color of the progress bar
        },
      }}
    />
  )
}
