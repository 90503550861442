import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CartProvider from '@context/CartContext';
import RealmProvider from '@context/RealmContext';
import localStorageNames from '@data/localStorageNames';
import DevelopmentPanel from '@components/DevelopmentPanel';
import { useGeneralContext } from './GeneralContext';
import { useTranslation } from 'react-i18next';
import { sendErrorData } from '@functions/ErrorMessagesSender';
import URLS from '@/URLS';
import SendEmailDialog from '@pages/checkout/SendEmailDialog';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreSettings } from '@state/useStoreSettings'
import { useStoreOutlet } from '@state/useStoreOutlet'
import { useStoreSpreadProduct } from '@state/useStoreSpreadProduct';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreCartItems } from '@state/useStoreCartItems';
const SUGGESTION_ENDPOINT =
  import.meta.env[`VITE_REACT_APP_SUGGESTION_ENDPOINT_${import.meta.env.MODE}`];

const PROMO_ENDPOINT =
  import.meta.env[`VITE_REACT_APP_PROMO_ENDPOINT_${import.meta.env.MODE}`];

const STRIPE_PAYMENT_ENDPOINT =
  import.meta.env[`VITE_REACT_APP_STRIPE_PAYMENT_ENDPOINT_${import.meta.env.MODE}`];

export const SEND_EMAIL_ENDPOINT =
  "https://email.rdo89.com/send-email";

export const GET_AUTH_EMAIL_ENDPOINT =
  "https://email.rdo89.com/api/auth/generateToken";

export const AuthContext = React.createContext([{}, () => { }]);

export const clearAllStorage = (param) => {
  if (param === 'partial') {
    useStoreCartItems.getState().removeItemInCart()
    // localStorage.removeItem(localStorageNames.CART_ITEMS);
    localStorage.removeItem(localStorageNames.FILLED_FEEDBACK);
    localStorage.removeItem(localStorageNames.USER);
  } else {
    Object.values(localStorageNames).forEach((LSname) => {
      if (
        LSname !== localStorageNames.TRANSACTIONS_ID &&
        LSname !== localStorageNames.LONG_URL
      ) {
        localStorage.removeItem(LSname);
      }
    });
  }
};

export const clearStorageAfterScan = () => {
  Object.values(localStorageNames).forEach((LSname) => {
    localStorage.removeItem(LSname);
  });
};

let splashUrl = '';
let logoUrl = '';

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const { openErrorSnackBar, openGeneralModal, showLoading } =
    useGeneralContext();
  const { t } = useTranslation();
  const [state, setState] = useState({
    feedbackResult: {
      error: false,
      message: '',
    },
    suggestion: {},
    promo: {},
    clientSecreet: '',
    tabCategory: {
      category: {},
      index: 0,
    },
    productTabSelected: { products: [] },
    fetchDataFinish: false,
    redirectTab: '',
    sendEmailVisible: false,
  });

  //!: Panggil credential dari state
  const { credentials, checkTokenIsExpired } = useStoreFirstCredential(state => ({
    credentials: state.credentials,
    checkTokenIsExpired: state.checkTokenIsExpired
  }))

  const { getProductList, getCategoryList } = useStoreProduct(state => ({
    getProductList: state.getProductList,
    getCategoryList: state.getCategoryList
  }))
  const { getSetting, getOrderMethod, setOrderMethod } = useStoreSettings(state => ({
    getSetting: state.getSetting,
    getOrderMethod: state.getOrderMethod,
    setOrderMethod: state.setOrderMethod
  }))
  const {
    getOutletInfo,
    getOutletName,
    getOutletId,
    getTableName,
    getSalesType,
    getActiveSalesType,
    setOpen,
    isOpen
  } = useStoreOutlet(state => ({
    getOutletInfo: state.getOutletInfo,
    getOutletName: state.getOutletName,
    getOutletId: state.getOutletId,
    getTableName: state.getTableName,
    getSalesType: state.getSalesType,
    getActiveSalesType: state.getActiveSalesType,
    setOpen: state.setOpen,
    isOpen: state.isOpen
  }))

  const { getColorApp } = useStoreAppColor(state => ({
    getColorApp: state.getColorApp
  }))

  const { getSpreadCategories } = useStoreSpreadProduct(state => ({
    getSpreadCategories: state.getSpreadCategories
  }))

  const setAuthState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getAuthState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const getLocalStorage = (key) => {
    return localStorage.getItem(key);
  };


  const setResultFeedback = (isError = false, message) => {
    setAuthState({
      feedbackResult: {
        error: isError,
        message: message,
      },
    });
  };

  const setSuggestionProduct = (suggestion) => {
    setAuthState({ suggestion: suggestion });
  };

  const setPromoProduct = (promo) => {
    setAuthState({ promo: promo });
  };

  //! Bisa Diambil dari state Spread Categories
  // const getSpreadCategories = () => {
  //   return JSON.parse(getLocalStorage(localStorageNames.SPREAD_CATEGORY));
  // };

  //! Bisa Diambil Dari state Feedback
  const getFeedbackList = () => {
    return JSON.parse(getLocalStorage(localStorageNames.FEEDBACK));
  };

  //TODO: Belum ada setup state userInfo
  const getUserPhone = () => {
    let userInfo = getLocalStorage(localStorageNames.USER);
    if (userInfo === 'guest') {
      return '';
    } else {
      return userInfo;
    }
  };


  const getSplashUrl = () => {
    return splashUrl;
  };

  const getLogoUrl = () => {
    return logoUrl;
  };

  const hitAPI = async (url, method, credential, data, token) => {
    const newUrl = url.replace(/\s/g, '');
    let feedback;
    try {
      const header = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'qrcode-token': credential.token,
        'qrcode-uuid': credential.uuid || credential.table,
      };
      if (credential === '') {
        delete header['qrcode-token'];
        delete header['qrcode-uuid'];
      }
      if (token) {
        header["Authorization"] = 'Bearer ' + token;
      }

      feedback = await fetch(newUrl, {
        method: method || 'GET',
        cache: 'no-cache',
        headers: header,
        body: JSON.stringify(data),
      });
      let response = await feedback.json();
      response.status = feedback.status;
      return response;
    } catch (error) {
      console.log(error);
      // generalErrorHandler(error, url, callback);
      // let unexpectedError = generalErrorHandler(error, newUrl, () =>
      //   openGeneralModal({
      //     title: t('networkError'),
      //     content: t('generalErrorHandlerMessage'),
      //   }),
      // );

      if (error) {
        let content = feedback
          ? JSON.stringify(feedback.json())
          : 'null feedback from server';
        let status = feedback ? feedback.status : null;
        const message = `${error.message} | ${content}`;

        sendErrorData({
          message: status !== null ? message.concat(` | ${status}`) : message,
          table: getTableName(),
          file: `AuthContext.js`,
          func: `hitAPI (${newUrl})`,
        });
      }

      return { error: true, messages: [error.message] };
    }
  };

  const isAuthenticated = () => {
    let credential = credentials
    if (
      credential.token !== null &&
      credential.token !== '' &&
      credential.table !== null &&
      credential.table !== ''
    ) {
      if (checkTokenIsExpired()) {
        // JIKA SESSION LEBIH DARI 30 MENIT
        sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
        history.replace(URLS.SESSION_EXPIRED);
      }
    } else {
      // JIKA TIDAK ADA TOKEN
      sendPostMessageToParent('path', URLS.INVALID_TOKEN);
      history.replace(URLS.INVALID_TOKEN);
    }
  };

  const sendFeedback = async (data) => {
    showLoading(true);
    setResultFeedback(false, '');

    const SEND_FEEDBACK_ENDPOINT =
      import.meta.env[`VITE_REACT_APP_CREATE_FEEDBACK_${import.meta.env.MODE}`];
    let credential = credentials || {};

    if (getTableName()) {
      data['table_number'] = getTableName();
    }

    hitAPI(SEND_FEEDBACK_ENDPOINT, 'POST', credential, data).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            showLoading(false);
            setResultFeedback(false, t('feedbackThanks'));
          } else {
            showLoading(false);
            let messages = '';
            if (response.messages && Array.isArray(response.messages)) {
              response.messages.map((data) => {
                messages += data + '\n';
                return null;
              });
            } else {
              messages = t('feedbackErrorMessage');
            }
            setResultFeedback(true, messages);
          }
        } else {
          showLoading(false);
          sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
          history.replace(URLS.SESSION_EXPIRED);
        }
      },
    );
  };

  const fetchSuggestion = async (data) => {
    showLoading(true);
    setResultFeedback(false, '');

    hitAPI(SUGGESTION_ENDPOINT, 'POST', '', data).then((response) => {
      if (response !== undefined && response !== null) {
        showLoading(false);

        if (response.status === 200) {
          setSuggestionProduct(response.result);
        } else if (response.status === 401) {
          sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
          history.replace(URLS.SESSION_EXPIRED);
        } else {
          setResultFeedback(true, JSON.stringify(response.response));
        }
      }
    });
  };

  const fetchPromo = async (data) => {
    showLoading(true);
    setResultFeedback(false, '');

    hitAPI(PROMO_ENDPOINT, 'POST', '', data).then((response) => {
      if (response !== undefined && response !== null) {
        showLoading(false);
        if (response.status === 200) {
          setPromoProduct(response.result);
        } else if (response.status === 401) {
          sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
          history.replace(URLS.SESSION_EXPIRED);
        } else {
          setResultFeedback(true, JSON.stringify(response.response));
        }
      }
    });
  };

  const fetchStripe = async (data) => {
    showLoading(true);
    let credential = credentials;
    hitAPI(STRIPE_PAYMENT_ENDPOINT, 'POST', credential, data).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 401) {
            showLoading(false);
            sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
            history.replace(URLS.SESSION_EXPIRED);
          } else if (response.status === 200) {
            showLoading(false);
            localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
            setAuthState({
              clientSecreet: response.result.client_secret,
            });
          } else {
            let messages = '';
            if (response.messages && Array.isArray(response.messages)) {
              response.messages.map((data) => {
                messages += data + '\n';
                return null;
              });
            }
            openErrorSnackBar(messages);
            showLoading(false);
          }
        }
      },
    );
  };

  const sendEmail = async (data) => {
    setAuthState({ sendEmailVisible: false })
    let param = {
      email: data.to
    }

    hitAPI(GET_AUTH_EMAIL_ENDPOINT, 'POST', '', param).then((response) => {
      if (!response.error) {
        const param2 = {
          to: data.to,
          from: import.meta.env[`VITE_REACT_APP_USER_EMAIL`],
          subject: 'RDO Receipts',
          path: 'https://email.rdo89.com/rdobody',
          body: {
            key: data.databody._id,
            data: data.databody
          }
        }
        hitAPI(SEND_EMAIL_ENDPOINT, 'POST', '', param2, response.token).then((response2) => {
          console.log(response2);
          //belum handle response
        });
      }
    });
  };

  const onClickTabCategory = (category, index) => {
    setAuthState({
      tabCategory: {
        category: category,
        index: index,
      },
    });
    let products = JSON.parse(
      getLocalStorage(`product.${category.uuid_product_category}`),
    );
    setAuthState({
      productTabSelected: {
        products: products,
      },
    });
  };

  // =================== HANYA UNTUK DEV PANEL ==============

  // const setOrderMethod = (value) => {
  //   let settings = getSetting() || {};
  //   settings.orderMethod = value;

  //   setLocalStorage(localStorageNames.SETTING, JSON.stringify(settings));
  // };

  // =================== HANYA UNTUK DEV PANEL ==============

  useEffect(() => {
    // JIKA URL PENDEK, CEK AUTENTIKASINYA, USE EFFECT INI BERJALAN TERUS KARENA TANPA '[]'
    if (!history.location.search) {
      isAuthenticated();
    }
  });
  console.log('auth context--------');
  return (
    <AuthContext.Provider
      value={{
        getAuthState,
        setAuthState,
        getOutletInfo,
        getOutletName,
        getOutletId,
        clearAllStorage,
        getTableName,
        getSalesType,
        getActiveSalesType,
        getCategoryList,
        getSpreadCategories,
        getProductList,
        getFeedbackList,
        hitAPI,
        isOpen,
        setOpen,
        sendFeedback,
        fetchSuggestion,
        fetchPromo,
        getSplashUrl,
        getLogoUrl,
        getSetting,
        getColorApp,
        getUserPhone,
        setOrderMethod,
        getOrderMethod,
        fetchStripe,
        onClickTabCategory,
        setLocalStorage,
        getLocalStorage,
        sendEmail
      }}> 
      <RealmProvider>
        <CartProvider>
          {getSetting()?.maintenance && (
            <div className="walkingContainer">
              <div className="walkingText">
                {/* TODO sementara tulisan maintenance mode dihilangkan (request mas yuda) */}
                {/* <b>MAINTENANCE MODE</b> */}
                <br />
              </div>
            </div>
          )}
          {children}
          {import.meta.env.MODE !== 'PRODUCTION' && <DevelopmentPanel />}
          {getAuthState('sendEmailVisible') && (
            <SendEmailDialog />
          )}
        </CartProvider>
      </RealmProvider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const value = useContext(AuthContext);
  if (value == null) {
    throw new Error('useAuthContext() called outside of a Provider?');
  }
  return value;
};

export default AuthProvider;
