import React, { useEffect } from 'react';
import '@adyen/adyen-web/dist/adyen.css';
import { getDefaultLogo } from '@functions/getImageUrlPath';
import { convertCurrency } from '@functions/convertNumber';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreViewBill } from '@state/useStoreViewBill';
import { useStoreLogo } from '@state/useStoreLogo';
import { useShallow } from 'zustand/react/shallow'
import { useCartContext } from '@context/CartContext';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreAppColor } from '@state/useStoreAppColor';

const PaymentDialog = () => {

    const { getContextState, setContextState, setConfirmPaymentAdyen } = useCartContext()
    const sessionAdyen = getContextState('confirmPaymentAdyen').data
    //TODO: Ubah ke stat
    const { outlet: outletInfo } = useStoreOutlet(state => ({
        outlet: state.outlet
    }))
    const { getSetting } = useStoreSettings(state => ({
        getSetting: state.getSetting
    }))
    const { logoUrl } = useStoreLogo(state => ({
        logoUrl: state.logoUrl
    }))
    const { updateViewBillId } = useStoreViewBill(useShallow(state => ({
        updateViewBillId: state.updateViewBillId
    })))
    const { appColor } = useStoreAppColor(state => ({
        appColor: state.appColor
    }))

    const handleCloseModal = () => {
        setConfirmPaymentAdyen(false);
        window.parent.postMessage(false, import.meta.env[`VITE_REACT_APP_DOMAIN_CONTAINER_${import.meta.env.MODE}`]);
    };

    const setConfirmModal = (value, orderMethod, parameter) => {
        setContextState({
            confirmModal: {
                visible: value,
                orderMethod: orderMethod,
                parameter: parameter,
            },
        });
    };


    const updateResultCode = (value) => {
        setContextState({ resultCode: value });
    };

    const getLogoImage = () => {
        if (logoUrl) return logoUrl;
        return getDefaultLogo();
    };

    const setPostMessageServer = () => {
        const receiveMessage = (event) => {
            if (event.origin === import.meta.env[`VITE_REACT_APP_DOMAIN_CONTAINER_${import.meta.env.MODE}`]) {
                if (event.data.key === "close") {
                    handleCloseModal();
                } else if (event.data.key === "adyen") {
                    updateResultCode(event.data.message.resultCode);
                    setConfirmModal(true, getSetting().orderMethod, '')
                    if (event.data.message.resultCode === "Authorised") {
                        //TODO: Change to state
                        updateViewBillId(sessionAdyen.result.reference.split('_')[0])

                    }
                    handleCloseModal();
                }
            }
        };

        window.addEventListener('message', receiveMessage);
    };

    useEffect(() => {
        setPostMessageServer();
        if (!sessionAdyen.error) {
            // createCheckoutSession();
            sessionAdyen.show = true;
            sessionAdyen.outletName = outletInfo.outletName;
            sessionAdyen.amount = convertCurrency(sessionAdyen.result.amount.value / 100);
            sessionAdyen.logo = getLogoImage();
            sessionAdyen.color = appColor;
            window.parent.postMessage(sessionAdyen, import.meta.env[`VITE_REACT_APP_DOMAIN_CONTAINER_${import.meta.env.MODE}`]);
        } else {
            sessionAdyen.show = true;
            sessionAdyen.color = appColor;
            window.parent.postMessage(sessionAdyen, import.meta.env[`VITE_REACT_APP_DOMAIN_CONTAINER_${import.meta.env.MODE}`]);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
        </>
    );
};

export default PaymentDialog;
