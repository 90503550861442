import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReDialog from '@components/ReDialog';
import theme from '@styles/theme';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { useCartContext } from '@context/CartContext';
import { useStoreSettings } from '@state/useStoreSettings';

const useStyles = makeStyles({
  modalStyle: {
    margin: '0 !important',
  },
  btnClose: {
    color: 'black !important',
    fontFamily: "Helvetica Neue !important",
  }
});

const QrCodeDialog = ({ closeModal, data = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { clearCart } = useCartContext();
  const [open, setOpen] = useState(false);
  const [tableName, setTableName] = useState(null);

  const { settings } = useStoreSettings(state => ({
    settings: state.settings
  }))

  const handleIfCloseTab = () => {
    window.addEventListener('beforeunload', alertUser);
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleClose = () => {
    setOpen(false);
    closeModal();
  };

  useEffect(() => {
    if (settings.tableName) {
      setTableName(settings.tableName);
    }
  }, []);

  useEffect(() => {
    handleIfCloseTab();
    clearCart();

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line
  }, []);

  const promptMessage = (location, action) => {
    if (action === 'POP') {
      setOpen(true);
      return false;
    }
  }

  const AlertDialog = () => {


    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" 
          style={{
            fontSize: '1.125rem',
            fontWeight: 500,
            lineHeight: 1.6,
            fontFamily: "Helvetica Neue",
          }}>
          {'Are you sure you want to leave this page?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} className={classes.btnClose}>
            Cancel
          </Button>
          <Button onClick={handleClose} className={classes.btnClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ReDialog
      modalStyle={classes.modalStyle}
      fullScreen={true}
      closeModal={closeModal}
      useCloseButton={false}
      contentStyle={{ paddingTop: theme.spacing(-10) }}
      content={
        <Box
          display="flex"
          flex="4"
          flexDirection="column"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh',
          }}>
          <AlertDialog />
          <Prompt
            when={true}
            message={promptMessage}
          />
          <Box
            display="flex"
            flexDirection="column"
            style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" style={{ marginBottom: theme.spacing(4), fontWeight: 'bold' }}>
              {tableName && `TABLE : ${tableName}`}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {
                (settings && settings.qrcodeTitlePage) ?
                  settings.qrcodeTitlePage :
                  t('qrcodeTitle')
              }
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
              {t('qrcodeSubTitle')}
            </Typography>
            <QRCode value={data} size="300" />
            <Typography variant="h6" style={{ marginTop: theme.spacing(4), fontWeight: 'bold' }}>
              {data}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default QrCodeDialog;
