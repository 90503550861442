import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

// useStoreCategorySectionId
export const useStoreCategory = create(persist((set) => ({
    category: {},
    categorySectionId: {},
    kodeCategory: '003',
    _hasHydratedCategory: false,
    setHasHydratedCategory: (state) => {
        set({  _hasHydratedCategory: state  });
    },
    updateCategorySectionId: (newData) => {
        set((state) => ({
            categorySectionId: {
                ...state.categorySectionId, // Preserve existing state
                ...newData, // Apply new data
            },
        }));
    },
    updateCategory: (newData) => {
        set({ category: newData })
    }
}), {
    name: localStorageNames.CATEGORY,
    onRehydrateStorage: () => (state) => {
        state.setHasHydratedCategory(true)
    }
}
)
)

export const useStoreScannerId = create(persist((set) => ({
    scannerResult: useStoreCategory.getState().category || {},
    updateScanner: (newData) => {
        set({ scannerResult: newData })
    }
}),
    {
        name: localStorageNames.CATEGORY
    }
)
)