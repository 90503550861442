import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Backdrop, CircularProgress } from '@mui/material';

const useStyles = makeStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.contrastText,
  },

});



const LoadingModal = (_props, ref) => {

  const [visible, setVisible] = useState(false);
  const classes = useStyles();

  const show = (flag) => {
    setVisible(flag);
  };

  useImperativeHandle(ref, () => ({ show }));


  return (
    <Backdrop
      className={classes.backdrop}
      sx={{ zIndex: 1500 }}
      open={ref ? visible : true}>
      <CircularProgress color="inherit" size={50} />
    </Backdrop>
  );
};

export default forwardRef(LoadingModal);
