import { create } from "zustand"
import { getProductData } from "@data/localStorageGetter";

export const useStoreProduct = create((set, get) => ({
    products: getProductData() || '',
    kodeProd: '001',
    updateProducts: (newCategories) => {
        console.log('update products ------');
        set({ products: JSON.stringify(newCategories) })
    },
    getProductList: () => {
        return JSON.parse(get().products)
    },
    getCategoryList: () => {
        const products = JSON.parse(get().products)
        let data =
            Array.isArray(products) &&
            products.map((newData) => {
                return { ...newData, isShow: false };
            });

        return data;
    }
}))