import React, { useState, useContext, useRef } from 'react';
import ReSnackbar from '@components/ReSnackbar';
import localStorageNames from '@data/localStorageNames';
import ReDialog from '@components/ReDialog';
import LoadingModal from '@components/LoadingModal';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import ServiceChargeLoading from '@components/ServiceChargeLoading';
import LostConnectionModal from '@components/LostConnection';

export const GeneralContext = React.createContext([{}, () => { }]);

const initialSnackbar = {
  visible: false,
  type: '', // error, success, warning
  message: '',
};

const GeneralProvider = ({ children }) => {


  const [state, setState] = useState({
    snackbar: initialSnackbar,
  });
  const _refGeneralDialog = useRef();
  const _refLoadingModal = useRef();
  const _refServiceModal = useRef()
  const _refLostConnection = useRef()

  const {
    getSellBandId,
    getTypeSalesName
  } = useStoreOrderCategory(state => ({
    getSellBandId: state.getSellBandId,
    getTypeSalesName: state.getTypeSalesName
  }))

  const setContextState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContextState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const getLoadingModal = () => {
    return _refLoadingModal.current || null;
  };

  const getServiceModal = () => {
    return _refServiceModal.current || null;
  }

  const getLostConnectionModal = () => {
    return _refLostConnection.current || null;
  }



  const getGeneralDialog = () => {
    return _refGeneralDialog.current || null;
  };

  const openGeneralModal = (data) => {
    return getGeneralDialog()?.setVisibility(true, data);
  };

  const closeGeneralModal = () => {
    return getGeneralDialog()?.setVisibility(false);
  };

  const setSnackbar = (visible, type, message) => {
    setContextState({
      snackbar: {
        visible: visible || false,
        type: type || '', // error, success, warning
        message: message || '',
      },
    });
  };

  const openSuccessSnackBar = (message) => {
    setSnackbar(true, 'success', message);
  };

  const openErrorSnackBar = (message) => {
    setSnackbar(true, 'error', message);
  };

  const openWarningSnackBar = (message) => {
    setSnackbar(true, 'warning', message);
  };


  const handleCloseSnackbar = () => {
    setContextState({ snackbar: initialSnackbar });
  };

  const getSnackbarVisibility = () => {
    return getContextState('snackbar').visible;
  };

  const openDrawer = () => {
    setContextState({
      drawer: true,
    });
  };

  const closeDrawer = () => {
    setContextState({
      drawer: false,
    });
  };

  const showLoading = (flag) => {
    getLoadingModal().show(flag);
  };

  const showServiceLoading = (flag) => {
    getServiceModal().show(flag)
  }

  const showLostConnectionModal = (flag) => {
    getLostConnectionModal().show(flag)
  }



  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const getLocalStorage = (key) => {
    return localStorage.getItem(key);
  };

  const setUserLocalStorage = (data) => {
    setLocalStorage(localStorageNames.USER, data);
  };



  return (
    <GeneralContext.Provider
      value={{
        getContextState,
        setContextState,
        openSuccessSnackBar,
        openErrorSnackBar,
        openWarningSnackBar,
        openDrawer,
        closeDrawer,
        getLocalStorage,
        setLocalStorage,
        setUserLocalStorage,
        getTypeSalesName,
        getSellBandId,
        openGeneralModal,
        closeGeneralModal,
        showLoading,
        showServiceLoading,
        showLostConnectionModal,
      }}>
      {children}
      {getSnackbarVisibility() && (
        <ReSnackbar
          show={true}
          type={getContextState('snackbar').type || 'success'}
          onClose={() => handleCloseSnackbar()}
          message={
            getContextState('snackbar').message || 'You can set the message'
          }
        />
      )}
      <ReDialog ref={_refGeneralDialog} useForwardRef useCloseButton={true} closeModal={() => { }} />
      <LoadingModal ref={_refLoadingModal} />
      <ServiceChargeLoading ref={_refServiceModal} />
      <LostConnectionModal ref={_refLostConnection} />
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => {
  const value = useContext(GeneralContext);
  if (value == null) {
    throw new Error('useGeneralContext() called outside of a Provider?');
  }
  return value;
};

export default GeneralProvider;
