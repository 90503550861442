import moment from 'moment-timezone';

export default function getDiffDate(timestamp) {
  if (timestamp) {

  }
  let oldStamp = moment(timestamp);

  let currentStamp = moment();
  let difference = currentStamp.diff(oldStamp, 'minutes');
  return difference;
}