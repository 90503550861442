import localStorageNames from './localStorageNames';
import { useStoreAppColor } from '@state/useStoreAppColor';
// GET LOCALSTORAGE
export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const getCredsFromStorage = () => {
  const creds = JSON.parse(getLocalStorage(localStorageNames.CREDENTIAL))
  return creds
}

export const getSettingData = () => {
  const settingsData = JSON.parse(getLocalStorage(localStorageNames.SETTING))
  return settingsData.state.settings;
};

export const getOutletData = () => {
  const outletData = getLocalStorage(localStorageNames.OUTLET);
  return outletData.state.outlet
};

export const getSplashData = () => {
  return getLocalStorage(localStorageNames.SPLASH);
};

export const getProductData = () => {
  return getLocalStorage(localStorageNames.PRODUCTS);
};


export const getColorApp = () => {
  const themeColor = useStoreAppColor.getState().appColor;

  return themeColor;
};

export const getLogoUrl = (data) => {
  return data || getLocalStorage(localStorageNames.LOGO);
};

export const getSpreadCategories = () => {
  return JSON.parse(getLocalStorage(localStorageNames.SPREAD_CATEGORY));
};

export const getCategoryList = () => {
  const products = getProductData();
  let data =
    Array.isArray(products) &&
    products.map((newData) => {
      return { ...newData, isShow: false };
    });

  return data;
};


// export const getOrderCategory = () => {
//   return JSON.parse(getLocalStorage(localStorageNames.ORDERCATEGORY))
// }