import useSplashImage from './useSplashImage';
import { useStoreSplashImage } from '@state/useStoreSplashImage';
import { useStoreLogo } from '@state/useStoreLogo';
import { useEffect } from 'react';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';

import { saveCredentials } from '@data/localStorageSetter';
import { useShallow } from 'zustand/react/shallow';

export const getSearchParam = window.location.href.split('?')[1]

export default function useCheckToken() {
    const getSplashScreen = useSplashImage()
    const { updateSplashScreen } = useStoreSplashImage(useShallow(state => ({ updateSplashScreen: state.updateSplashScreen })))
    const { updateLogoUrl } = useStoreLogo(useShallow(state => ({ updateLogoUrl: state.updateLogoUrl })))

    const { credentials, updateCredentials } = useStoreFirstCredential(useShallow(state => ({
        credentials: state.credentials,
        updateCredentials: state.updateCredentials

    })))


    const setUrlToken = async () => {
        updateSplashScreen(getSplashScreen("splash"))
        updateLogoUrl(getSplashScreen('logo'))
        //Untuk menyimpan Credentials kedalam localStorage
        updateCredentials(credentials)
    }

    if (getSearchParam) {
        saveCredentials(credentials)
    }

    useEffect(() => {

        getSplashScreen("splash")
        // Result tidak boelh null,
        // redirect ke page error


        setUrlToken()
    }, [getSearchParam])

    return credentials
}
