import { create } from "zustand"
import { persist } from "zustand/middleware"
export const useStoreGenerateKey = create(persist((set) => ({
    userUuid: "",
    generateUniqueKey: () => {
        const randomPart = Math.random().toString(36).substring(2, 10);
        const timestampPart = Date.now().toString(36);
        set({
            userUuid: randomPart + timestampPart
        })
    },

}),
    {
        name: "generateToken",
    }
))















