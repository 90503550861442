import URLS from '@/URLS';
import { useEffect, useState } from "react";
import { useStoreFirstCredential } from "@state/useStoreFirstCredential";
import {
    sendPostMessageToParent,
} from '@functions/trafficController';
import { useStoreNutriGrade } from "@state/useStoreNutriGrade";
import { useHistory } from 'react-router-dom';
// import { sendErrorData } from '@functions/ErrorMessagesSender';
import { useShallow } from 'zustand/react/shallow'

const useRealmFetch = () => {
    const history = useHistory();
    const { credentials, getUserLogin } = useStoreFirstCredential(state => ({
        credentials: state.credentials,
        getUserLogin: state.getUserLogin
    }))
    const { updateNutriGrade } = useStoreNutriGrade(useShallow(state => ({ updateNutriGrade: state.updateNutriGrade })))

    const [realmConfig, setRealmConfig] = useState()

    async function fetchNutriGrade() {
        try {
            const userLogin = await getUserLogin()
            const response = await userLogin.callFunction(
                'getProductNutriGrade'
            );
            updateNutriGrade(response)
        } catch (error) {
            // sendErrorData({
            //     message: `Error Fetch Nutri Grade - ${error}`,
            //     table: realmConfig?.config.tableName,
            //     file: 'useRealmFetch.js',
            //     func: 'fetchNutriGrade()',
            //     level: 'info',
            // });
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    async function fetchRealmConfig() {
        try {
            const userLogin = await getUserLogin()
            const response = await userLogin.callFunction(
                'getDataAuthStore',
                credentials);
            setRealmConfig(response)
        } catch (error) {
            // sendErrorData({
            //     message: `Error Fetch Config - ${error}`,
            //     table: realmConfig?.config.tableName,
            //     file: 'useRealmFetch.js',
            //     func: 'fetchRealmConfig()',
            //     level: 'info',
            // });
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }


    useEffect(() => {
        fetchRealmConfig()
        fetchNutriGrade()
    }, [credentials])

    return realmConfig
}

export default useRealmFetch