import { create } from "zustand"
import { persist } from "zustand/middleware"

import localStorageNames from "@data/localStorageNames";

export const useStoreSpreadProduct = create(persist((set, get) => ({
    spreadProducts: {},
    kodeSpreadCategory: '010',
    _hasHydratedSpreadCategory: false,
    setHasHydratedSpreadCategory: (state) => {
        set({  _hasHydratedSpreadCategory: state  });
    },
    updateSpreadProduct: (newSpreadProducts) => {
        set({ spreadProducts: newSpreadProducts })
    },
    getSpreadCategories: () => {
        return get().spreadProducts
    }
}),
    {
        name: localStorageNames.SPREAD_CATEGORY,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedSpreadCategory(true)
        }
    }
))