import React, { useEffect, useState } from 'react';
import { Typography, Grid, CircularProgress, Button, Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CancelOutlined, CheckCircle, ListAlt } from '@mui/icons-material';
import { useCartContext } from '@context/CartContext';
import theme from '@styles/theme';
import ReDialog from '@components/ReDialog';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@context/AuthContext';
import QrCodeDialog from './QrCodeDialog';
import { useRealmContext } from '@context/RealmContext';

import { useHistory } from 'react-router-dom';
import URLS from '@/URLS';
import { useGeneralContext } from '@context/GeneralContext';

import * as Realm from 'realm-web';
import moment from 'moment-timezone';
import { sendPostMessageToParent } from '@functions/trafficController';
import {
  useStoreAppColor
} from '@state/useStoreAppColor';
import { useStoreOrderNumber } from '@state/useStoreOrderNumber';
import { useStoreViewBill } from '@state/useStoreViewBill';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useConfirmDialog } from '@state/checkout/useConfirmDialog';
import { styled } from "@mui/system"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
const useStyles = makeStyles({
  titleStyle: {
    paddingBottom: 0,
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonNumber: {
    margin: 7,
    height: 60,
    '& .MuiButton-label': {
      width: '100%',
      fontSize: 12,
    }
  },
  button: {
    width: '200px',
    fontWeight: 'bold',
  },
  confirmText: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  emailReceipt: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& .MuiInputLabel-root': {
      color: props => props.primaryFontColor
    },
    '& ::placeholder': {
      color: theme.palette.text.disabled,
    },
    '& label.Mui-focused': {
      color: props => props.primaryFontColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: props => props.primaryFontColor
      },
    },
  },
  iconDialog: {
    display: 'flex',
  },
  iconDialog2: {
    position: 'absolute',
    top: -65,
    right: -5,
    fontSize: 30,
  },
});


const ModalOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw', // Cover the entire viewport width
  height: '100vh', // Cover the entire viewport height
  backgroundColor: 'rgba(0,0,0,.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically
  zIndex: 1300,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Corrected the typo from 'alignItem'
  backgroundColor: 'white',
  width: '95%', // You can adjust this to your preferred width
  maxWidth: '600px', // Optional: Limit the maximum width
  padding: '16px', // Optional: Add padding
  borderRadius: theme.shape.borderRadius, // Optional: Rounded corners
  overflowY: 'auto', // Allow scrolling if content overflows
}));



const SuccessConfirm = () => {
  const { getContextState, setContextState, getResultCode: resultCode } = useCartContext()
  const paymentVisible = getContextState('paymentResId').visible
  const { t } = useTranslation()
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const classes = useStyles(appColor);

  const { forceReset } = useCartContext();
  //TODO: Gunakan state untuk getSettting & getOutletInfo
  const { sendEmail } = useAuthContext();
  const { getDetailTransaction } = useRealmContext();
  const { openErrorSnackBar, showLoading } = useGeneralContext();
  const history = useHistory();
  const [emailReceipt, setEmailReceipt] = useState('');
  const [isErrorTextField, setErrorTextField] = useState(false);

  //TODO: Ubah orderNumber ke state
  const { orderNumber } = useStoreOrderNumber(state => ({
    orderNumber: state.orderNumber
  }))
  const { outlet: outletInfo } = useStoreOutlet(state => ({
    outlet: state.outlet
  }))
  const { getSetting } = useStoreSettings(state => ({
    getSetting: state.getSetting
  }))

  const { viewBillId: transId } = useStoreViewBill(state => ({
    viewBillId: state.viewBillId
  }))

  const { removeIsViewBill } = useStoreViewBill(state => ({
    removeIsViewBill: state.removeIsViewBill
  }))
  const { clearCart } = useStoreCartItems(state => ({
    clearCart: state.clearCart
  }))

  const handleClearCart = () => {
    removeIsViewBill()
    // localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
    clearCart();
    setContextState({
      paymentResId: {
        visible: false,
      },
    });
  };

  const setConfirmModal = (value, orderMethod, parameter) => {
    setContextState({
      confirmModal: {
        visible: value,
        orderMethod: orderMethod,
        parameter: parameter,
      },
    });
  };

  const closeModal = () => {
    setConfirmModal(false, null, null);
  };

  const handleConfirmBtn = () => {
    if (getSetting().orderMethod === 'payment_order') {
      if (resultCode() === "Authorised") {
        forceReset();
        handleClearCart();
      } else {
        //close modal
        closeModal();
      }
    } else {
      forceReset();
    }
  };

  const getNameSalesType = (paramId) => {
    if (paramId) {
      // let outletInfo = getOutletInfo;
      let salesTypes = outletInfo.salestype.sales;

      let index = salesTypes.findIndex(
        (x) => x.type_sales_id.toString() === paramId.toString(),
      );

      return salesTypes[index]?.title;
    }
    return null;
  };


  const handleSendEmail = async () => {
    const valid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailReceipt);
    if (valid) {
      showLoading(true);
      setErrorTextField(false);
      let orderMethod = getSetting().orderMethod;
      let filter = {
        _id: new Realm.BSON.ObjectId(transId),
        table_id: outletInfo.tableId,
        status: "close",
        type_bill: orderMethod,
      };

      const substract = moment().subtract(3, 'h');
      filter['createdAt'] = { $gt: substract.toDate() };
      let queryTrans = await getDetailTransaction(filter);
      if (queryTrans[0].status !== 'close') {
        setTimeout(async () => {
          handleSendEmail();
        }, 1000);
      } else {
        queryTrans[0].salestype = getNameSalesType(queryTrans[0].transaction_items[0].sales_type);
        queryTrans[0].createdAt = moment(queryTrans[0].createdAt).format('MM-DD-YYYY HH:mm:ss');
        queryTrans[0].outletInfo = outletInfo;
        queryTrans[0].customTable = getSetting().customTable;
        ///send email
        let dataEmail = {
          to: emailReceipt,
          databody: queryTrans[0],
        }
        setTimeout(async () => {
          await sendEmail(dataEmail);
          showLoading(false);
          forceReset();
          handleClearCart();
        }, 1000);
      }
    } else {
      setErrorTextField(true);
      openErrorSnackBar("Please fill in your email validly");
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        {
          getSetting().orderMethod === 'payment_order' ?
            <Box className={classes.iconDialog} sx={{ position: 'relative', display: 'inline-block' }}>
              <ListAlt style={{ fontSize: 80 }} />
              {resultCode() === "Authorised" ? (
                <CheckCircleIcon className={classes.iconDialog2}
                  sx={{
                    color: theme.palette.success.main,
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    fontSize: 30,
                    transform: 'translate(50%, -50%)', // Adjust to fine-tune the position
                  }}
                />
              ) : (
                <CancelIcon className={classes.iconDialog2}
                    sx={{
                      color: 'red',
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      fontSize: 30,
                      transform: 'translate(50%, -50%)', // Adjust to fine-tune the position
                    }}
                />
              )}
            </Box>
            : (
              <CheckCircleIcon
                sx={{ color: theme.palette.success.main, fontSize: '3rem' }}
              />
            )
        }
        <Grid direction="column" sx={{ display: "flex", justifyContent: "center", aligItems: "center" }}>
          <Grid item>
            {getSetting().orderMethod === 'waiter_order' && (
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                {t('thankyou')}
              </Typography>
            )}

            <Typography sx={{ textAlign: 'center' }}>
              {getSetting().orderMethod === 'waiter_order' && (
                t('waiterOrderComplete')
              )}
            </Typography>

            <Typography sx={{ textAlign: 'center', marginBottom: theme.spacing(6), }}>
              {getSetting().orderMethod === 'payment_order' && (
                //TODO: tampilkan order number dari lcoalstorage
                //custom table raptor/rdo
                resultCode() === "Authorised" ?
                  (<div>
                    Payment Successful, <br /> Your order has been confirmed and sent to the kitchen!
                  </div>)
                  : "Payment Failed, Please try again !"
              )}
            </Typography>
            {(getSetting().orderMethod === 'payment_order' && resultCode() === "Authorised" && (getSetting().customTable === "raptor" || getSetting().customTable === "rdo")) &&
              <Grid
                container
                direction="row"
                justifyContent="center">
                {
                  orderNumber ?
                    <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }}>
                      Order No : {orderNumber}
                    </Typography>
                    :
                    <Grid item sx={{ margin: 'auto' }}>
                      <Button
                        onClick={() => {
                          handleConfirmBtn();
                          sendPostMessageToParent('path', URLS.PAYMENT_BILL);
                          history.replace(URLS.PAYMENT_BILL);
                        }}
                        className={classes.buttonNumber}
                        sx={{
                          color: appColor.secondaryFontColor,
                          backgroundColor: appColor.primaryThemeColor,
                          '&:hover': {
                            backgroundColor: appColor.primaryThemeColor
                          }
                        }}
                        variant="contained">
                        Click here to view
                        <br />
                        your order number
                      </Button>

                    </Grid>
                }
              </Grid>
            }
            <Typography sx={{ textAlign: 'center' }}>
              {getSetting().orderMethod === 'normal_order' && (
                t('orderComplete')
              )}
            </Typography>
          </Grid>

          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 5, marginBottom: theme.spacing(6) }}>
            {getSetting().orderMethod === 'payment_order' && resultCode() === "Authorised" && (
              <Grid sx={{ width: '50%', paddingTop:theme.spacing(6) }}>
                <Button
                  onClick={() => {
                    handleConfirmBtn();
                    sendPostMessageToParent('path', URLS.PAYMENT_BILL);
                    history.replace(URLS.PAYMENT_BILL);
                  }}
                  className={classes.buttonConfirm}
                  sx={{
                    color: appColor.secondaryFontColor,
                    backgroundColor: appColor.primaryThemeColor,
                    height: getSetting().orderMethod === 'payment_order' ? 60 : "auto",
                    width: '100%',
                    '&:hover': {
                      backgroundColor: appColor.primaryThemeColor
                    }
                  }}
                  variant="contained">
                  {/* TODO: di ganti view order untuk custom table raptor/rdo */}
                  {(getSetting().customTable === "raptor" || getSetting().customTable === "rdo") ?
                    <> View <br /> Order </>
                    : <> View <br /> Receipt </>
                  }
                </Button>
              </Grid>
            )}

            <Grid sx={{ width: getSetting().orderMethod === 'payment_order' && resultCode() === "Authorised" ? '50%' : "100%", paddingTop:theme.spacing(6) }} id="continueBrowsing">
              <Button
                id="confirmSuccess"
                onClick={handleConfirmBtn}
                className={classes.buttonConfirm}
                sx={{
                  color: appColor.secondaryFontColor,
                  backgroundColor: appColor.primaryThemeColor,
                  margin: 'auto',
                  height: getSetting().orderMethod === 'payment_order' ? 60 : "auto",
                  width: '100%',
                  '&:hover': {
                    backgroundColor: appColor.primaryThemeColor
                  }
                }}
                variant="contained">
                {getSetting().orderMethod === 'payment_order' ? "Continue Browsing" : t('confirm')}
              </Button>
            </Grid>
          </Grid>
          {
            paymentVisible &&
            resultCode() === "Authorised" && (
              <Grid item>
                <TextField
                  inputProps={{ maxLength: 36 }}
                  className={classes.emailReceipt}
                  autoComplete="off"
                  id="emailReceipt"
                  label="Email receipt"
                  placeholder="Email receipt"
                  variant="outlined"
                  size="small"
                  type="email"
                  required={true}
                  error={isErrorTextField}
                  onChange={(event) => {
                    setEmailReceipt(event.target.value);
                  }}
                />
                <Grid item sx={{ width: '100%' }}>
                  <Button
                    onClick={() => {
                      handleSendEmail();
                    }}
                    sx={{
                      color: appColor.secondaryFontColor,
                      backgroundColor: appColor.primaryThemeColor,
                      width: '100%',
                      marginTop: 3,
                      '&:hover': {
                        backgroundColor: appColor.primaryThemeColor
                      }
                    }}
                    variant="contained">
                    Send Email
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>

      </ModalContainer>
    </ModalOverlay>
  );
};

const ConfirmDialog = ({
  closeQRcode,
  confirmTransaction,
}) => {
  const { getContextState } = useCartContext()
  const paymentVisible = getContextState('paymentResId').visible

  const { t } = useTranslation();
  const {
    confirmed,
    setConfirmed,
    openQRcode,
    setOpenQRcode
  } = useConfirmDialog(state => ({
    confirmed: state.confirmed,
    setConfirmed: state.setConfirmed,
    openQRcode: state.openQRcode,
    setOpenQRcode: state.setOpenQRcode
  }))

  //TODO: Change setting to state
  const { getSetting } = useStoreSettings(state => ({
    getSetting: state.getSetting
  }))

  const handleCloseQRcode = () => {
    setOpenQRcode({ visible: false, data: null });
    closeQRcode();
  };

  useEffect(() => {
    setConfirmed(paymentVisible);
    // eslint-disable-next-line
  }, [paymentVisible]);

  return (
    <>
      <ModalOverlay>
        <ModalContainer>
          <Typography variant="h6" sx={{fontSize: '1.125rem !important', fontWeight: 'bold', marginBottom: theme.spacing(4)}}>
            {t('confirmOrder').toUpperCase()}
          </Typography>
          <ConfirmDialogContent confirmTransaction={confirmTransaction} />
          {getSetting().orderMethod === 'quick_order'
            ? confirmed &&
            openQRcode.visible && (
              <QrCodeDialog
                closeModal={() => handleCloseQRcode()}
                data={openQRcode.data}
              />
            )
            : confirmed && <SuccessConfirm />}
        </ModalContainer>
      </ModalOverlay>
    </>
  );
};

const ConfirmDialogContent = ({ confirmTransaction }) => {
  const { getContextState, setContextState } = useCartContext()
  const messageDialog = getContextState('confirmPayment').messageDialog
  const {
    setConfirmed,
    setOpenQRcode
  } = useConfirmDialog(state => ({
    confirmed: state.confirmed,
    setConfirmed: state.setConfirmed,
    openQRcode: state.openQRcode,
    setOpenQRcode: state.setOpenQRcode
  }))
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const setConfirmModal = (value, orderMethod, parameter) => {
    setContextState({
      confirmModal: {
        visible: value,
        orderMethod: orderMethod,
        parameter: parameter,
      },
    });
  };

  const closeModal = () => {
    setConfirmModal(false, null, null);
  };

  const classes = useStyles(appColor);
  const { t } = useTranslation();
  const [isButtonClick, setButtonClick] = useState(false);
  const { getLocalState } = useRealmContext();

  //TODO: Change setting to state
  const { getSetting } = useStoreSettings(state => ({
    getSetting: state.getSetting
  }))

  const getBodyText = () => {
    if (messageDialog) {
      return messageDialog;
    }
    if (getSetting() && getSetting().orderMethod !== 'payment_order') {
      return getSetting().confirmOrderText;
    }
    return t('confirmOrderMessage');
  };

  const handleClickButton = async () => {
    setButtonClick(true);
    let confirmTrans = await confirmTransaction();
    const transaction = JSON.parse(confirmTrans);
    if (transaction.status === 'success') {
      setConfirmed(true);
      if (getSetting().orderMethod === 'quick_order') {
        setOpenQRcode({
          visible: true,
          data: transaction.parameter,
        });
      }
    } else {
      setButtonClick(false);
    }
  }
  return (
    <Grid direction="column" id="content">
      <Grid item>
        <Typography className={classes.confirmText}>
          {isButtonClick ? t('waitingOrder') : getBodyText()}
        </Typography>
      </Grid>
      {isButtonClick ? (
        <Grid
          direction="column"
          justify="center"
          alignItems="center"
          sx={{textAlign: 'center', paddingTop: theme.spacing(3)}}>
          <CircularProgress sx={{ color: appColor.secondaryFontColor }} />
          <Box sx={{marginTop: theme.spacing(2)}}>
            {getLocalState('messageRetry') && (
              <Typography className={classes.confirmText}>
                {t('retryConfirm')}{getLocalState('messageRetry')}
              </Typography>
            )}
          </Box>
        </Grid>
      ) : (
        <Grid container direction="row" sx={{ textAlign: 'center', display: "center", alignItems: "center", justifyContent: "space-evenly", marginTop: "1rem" }}>
          <Grid item sx={{ width: '50%' }}>
            <Button
              onClick={closeModal}
              className={classes.buttonConfirm}
              sx={{
                color: 'black',
                height: getSetting().orderMethod !== 'payment_order' && "auto",
                backgroundColor: '#e0e0e0',
                width: '95%',
                marginRight: theme.spacing(2),
                '&:hover': {
                  backgroundColor: '#e0e0e0'
                }
              }}
              variant="contained">
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item sx={{ width: '50%'}}>
            <Button
              id="confirmOrder"
              onClick={handleClickButton}
              className={classes.buttonConfirm}
              sx={{
                color: appColor.secondaryFontColor,
                backgroundColor: appColor.primaryThemeColor,
                height: getSetting().orderMethod !== 'payment_order' && "auto",
                width: '95%',
                marginLeft: theme.spacing(2),
                '&:hover': {
                  backgroundColor: appColor.primaryThemeColor
                }
              }}
              variant="contained">
              {`${t('confirm')}`}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ConfirmDialog;
