import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Backdrop, Modal, Fade, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import { useCartContext } from '@context/CartContext';
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/system"

const ModalContent = styled('div')(({ theme }) => ({
    width: "70%",
    maxWidth: 500,
    height: "auto",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    background: "transparent",
    textAlign: 'center',
    outline: 'none',
    margin: 'auto', // Center horizontally
    position: 'absolute',
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Adjust position to ensure exact center
}));

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndex.drawer + 2,
        outline: 'none', // Remove default modal outline
    },
    modalContent: {
        position: 'relative',
        width: "50%",
        maxWidth: 500, // Limit the maximum width
        padding: theme.spacing(3),
        paddingTop: theme.spacing(6),
        backgroundColor: 'white',
        borderRadius: theme.shape.borderRadius, // Add rounded corners
        textAlign: 'center', // Center text content
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
})

const CheckingSubtotalModal = (_props) => {
    const classes = useStyles();
    const { t } = useTranslation()
    const { setCheckoutPage, getContextState, setContextState } = useCartContext();
    const [visible, setVisible] = useState(getContextState('subtotalWarning').visible)
    const handleClose = () => {
        setVisible(false)
        setCheckoutPage(true)
        setContextState({
            paymentResId: {
                visible: false,
            },
        });
        setContextState({
            subtotalWarning: {
                visible: false,
            },
        });
    }


    return (
        <Modal
            aria-labelledby="checking-subtotal-modal"
            aria-describedby="resolving-subtotal"
            open={visible}
            onClose={handleClose}
            slots={{ backdrop: Backdrop }}
            className={classes.modal}
        >
            <Fade in={visible}>
                <ModalContent>
                    <Alert icon={<WarningIcon sx={{ fontSize: 32 }} />}
                        severity="warning"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center"
                        }}>
                        <AlertTitle >{t('billIssue')}</AlertTitle>
                        <Typography sx={{ paddingBottom: 2 }} variant="body2">{t('billIssueDesc')}</Typography>
                        <Typography sx={{ paddingBottom: 2 }} variant="body2">{t('tryAgain')}</Typography>
                    </Alert>
                </ModalContent>
            </Fade>
        </Modal >
    );
}


export default CheckingSubtotalModal