export const checkAdditionalRequest = (additionalRequest) => {
    return (
        Array.isArray(additionalRequest) &&
        additionalRequest !== null &&
        additionalRequest.length > 0
    );
};

export const checkSubProduct = (subProduct) => {
    return (
        Array.isArray(subProduct) && subProduct !== null && subProduct.length > 0
    );
};

export const checkPrepGroup = (prepGroup) => {
    return (
        Array.isArray(prepGroup) && prepGroup !== null && prepGroup.length > 0
    );
};

export const checkSelectTime = (selectTime) => {
    return selectTime;
};

export const checkPrepService = (serviceCharge) => {
    return (
        Array.isArray(serviceCharge) && serviceCharge !== null && serviceCharge.length > 0
    );
};

export const checkValue = (additionalRequest, subProduct, prepGroup, selectTime, serviceCharge) => {
    return checkAdditionalRequest(additionalRequest) || checkSubProduct(subProduct) || checkPrepGroup(prepGroup) || checkSelectTime(selectTime) || checkPrepService(serviceCharge)
};
