import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

export const useStoreSiccMember = create(persist((set) => ({
    siccMember: "",
    updateSiccMember: (newMember) => {
        set({ siccMember: newMember })
    },
    clearSiccMember: () => set({ siccMember: "" })
}),
    {
        name: localStorageNames.SICC_MEMBER
    }
)
)