import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames"

export const useStoreAppColor = create(persist((set, get) => ({
    appColor: {
        primaryThemeColor: '#FF8A37',
        secondaryThemeColor: '#FEEDE2',
        primaryFontColor: '#000000',
        secondaryFontColor: '#ffffff',
        backgroundColor: '#FFE7D7',
        backgroundProduct: '#FFFFFF',
        headerAccordion: '#FF911C',
        successAccordion: '#00AA13',
        addButton: '#00AA13',
        backgroundAccordion: '#FFFFFF',
    },
    kodeColor: '002',
    _hasHydratedColor: false,
    setHasHydratedColor: (state) => {
        set({  _hasHydratedColor: state  });
    },
    updateAppColor: (newAppColor) => {
        set({ appColor: newAppColor })
    },
    getPrimaryThemeColor: () => {
        return get().appColor.primaryThemeColor || '#FF8A37'
    },
    getSecondaryThemeColor: () => {
        return get().appColor.secondaryThemeColor || '#FEEDE2'
    },
    getPrimaryFontColor: () => {
        return get().appColor.primaryFontColor || '#000000'
    },
    getSecondaryFontColor: () => {
        return get().appColor.secondaryFontColor || '#ffffff'
    },
    getBackgroundColor: () => {
        return get().appColor.backgroundColor || '#FFE7D7'
    },
    getColorApp: () => {
        return get().appColor
    }
}),
    {
        name: localStorageNames.THEME_COLOR,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedColor(true)
        }
    }
))