import { useGeneralContext } from '@context/GeneralContext';
import { useState, useEffect } from 'react';

const useOnlineStatus = () => {
    const [isOnline, setIsOnline] = useState(null);
    const checkOnlineStatus = async () => {
        try {
            await fetch('https://ap-southeast-1.aws.services.cloud.mongodb.com', {
                method: 'HEAD',
                mode: 'no-cors'
            });
            setIsOnline(true);
            return true
        } catch (error) {
            setIsOnline(false);
            return false
        }
    };

    useEffect(() => {
        // Initial check
        checkOnlineStatus();
    }, []);

    return [isOnline, checkOnlineStatus];
};

export default useOnlineStatus;
