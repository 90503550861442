import packageJson from '~/package.json';
import * as Sentry from '@sentry/browser';
import localStorageNames from '@data/localStorageNames';

export const checkMode = () => {
  if (import.meta.env.MODE !== 'PRODUCTION') {
    document.title = `${import.meta.env.MODE} - ${document.title}`;
    console.log(`environment : ${import.meta.env.MODE}`);
    console.log(
      `cdn path : ${import.meta.env[`VITE_REACT_APP_CDN_URL_${import.meta.env.MODE}`]
      }`,
    );
    console.log(`version code : ${packageJson.version}`);
  }
}


export const initContextSentry = (longURL) => {
  var token = new URLSearchParams(window.location.search).get('token');
  if (window.location.search && token) {
    longURL = decodeURIComponent(window.location.href);
    localStorage.setItem(localStorageNames.LONG_URL, longURL);
  } else {
    longURL = localStorage.getItem(localStorageNames.LONG_URL);
  }
  Sentry.setContext('URL', {
    Long_URL: longURL,
  });
};

export const setPostMessageServer = () => {
  const receiveMessage = (event) => {
    if (event.origin === import.meta.env[`VITE_REACT_APP_DOMAIN_CONTAINER_${import.meta.env.MODE}`]) {
      if (event.data.key === "server") {
        localStorage.setItem(localStorageNames.DOMAIN_SERVER, event.data.message);
      }
    }
  };


  window.addEventListener('message', receiveMessage);
};
