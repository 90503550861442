import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useStoreAppColor } from '@state/useStoreAppColor';
import useStyles from './checkout.styles';

const OrderedPackage = ({ subProduct, qtyProduct }) => {
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const classes = useStyles(appColor)
  return (
    <>
      {Array.isArray(subProduct) &&
        subProduct.map((item, index) => {
          return (
            <Grid
              key={item.uuid_product || index}
              container
              direction="row"
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item style={{ flex: 4 }}>
                <Typography
                  id={`title-${index}`}
                  variant="caption"
                  className={`${classes.modifierText} ${classes.textWrapper}`}
                  style={{ width: '95%' }}>
                  {`+${item.plu_name}`}
                </Typography>
              </Grid>
              <Grid item style={{ flex: 1, textAlign: 'right' }}>
                <Typography
                  id={`quantity-${index}`}
                  variant="caption"
                  className={classes.modifierText}>
                  x {item.quantity * qtyProduct}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

export default OrderedPackage;
