import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function BrowserNotSupported() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <h1 style={{ textAlign: 'center' }}>Browser is not supported</h1>
      <h2 style={{ textAlign: 'center' }}>
        Please upgrade your browser version or change the browser
      </h2>
    </Box>
  );
};
