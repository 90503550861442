import { useStoreAppColor } from '@state/useStoreAppColor';

const themeColor = useStoreAppColor.getState().appColor
export const getPrimaryThemeColor = () => {
  return themeColor?.primaryThemeColor || '#FF8A37';
};

export const getSecondaryThemeColor = () => {
  return themeColor?.secondaryThemeColor || '#FEEDE2';
};

export const getPrimaryFontColor = () => {
  return themeColor?.primaryFontColor || '#000000';
};

export const getSecondaryFontColor = () => {
  return themeColor?.secondaryFontColor || '#ffffff';
};

export const getBackgroundColor = () => {
  return themeColor?.backgroundColor || '#FFE7D7';
};