import { create } from "zustand"
import { persist } from "zustand/middleware"

import localStorageNames from "@data/localStorageNames";


export const useStorePayment = create(persist((set) => ({
    paymentMethod: "normal_order",
    updatePayment: (newPayment) => {
        set({ paymentMethod: newPayment })
    }
}),
    {
        name: localStorageNames.PAYMENT_METHOD
    }

))