import packageJson from '~/package.json';
import localStorageNames from '@data/localStorageNames';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/browser';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';

export const sendErrorData = async (data) => {
  const { outlet: outletInfo } = useStoreOutlet.getState()
  const { credentials: creds } = useStoreFirstCredential.getState()

  // GET RDO TIME FROM LOCALSTORAGE
  const timeError = moment(new Date()).format('HH:mm:ss');
  let timeLogin = "-";
  if (creds) {
    timeLogin = moment(
      creds['login-stamp'],
      'x',
    ).format('HH:mm:ss');
  }

  Sentry.setTag('RDO_CUSTOMER', `${outletInfo?.outletName}`);
  Sentry.setTag('RDO_VERSION', `${packageJson.version}`);

  Sentry.setContext('ERROR FRONT END WEB', {
    outlet: outletInfo?.outletName,
    outlet_id: outletInfo?.outletId,
    time_login: timeLogin,
    time_error: timeError,
    table_name: outletInfo?.tableName,
    table_id:
      outletInfo?.tableId ||
      localStorage.getItem(localStorageNames.CUSTOM_PARAMETER),
    file: `${data.file} - ${data.func}`,
    payload: data.payload ? data.payload : creds,
  });

  Sentry.captureException(data.message, {
    level: data.level ? data.level : 'error',
  });
};
