import { useStoreOutlet } from '@state/useStoreOutlet';

export function convertCurrency(number, withCurrency = true) {
  const { outlet } = useStoreOutlet.getState()

  let param = {
    style: 'currency',
    currency: outlet.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }
  if (!withCurrency) delete param.style;
  let result = new Intl.NumberFormat(outlet.localeCode, param)
    .format(outlet.localeCode === 'id-ID' ? Math.round(number) : number);

  if (outlet.localeCode === 'id-ID') {
    let split = result.split(',');
    result = split[0];
  }

  if (!withCurrency) {
    result = result.replace(outlet.currencySymbol, '').trim();
  }

  return result;
}
