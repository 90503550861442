const URLS = {
  SPLASH: '/splash',
  LOGIN: '/login',
  SUGGESTION: '/suggestion',
  HISTORY_BILL: '/history-bill',
  MENU_CATALOGUE: '/menu-catalogue',
  ORDER_CATEGORY: '/order-category',
  CATEGORY: '/category',
  VIEW_BILL: '/view-bill',
  SESSION_EXPIRED: '/session-expired',
  INVALID_TOKEN: '/invalid-token',
  CONNECTION_FAILURE: '/connection-failure',
  PAYMENT_STRIPE: '/payment-stripe',
  PAYMENT: '/payment',
  DETAIL_TRANSACTION: '/detail-transaction',
  VIEW_TRANSACTION: '/view-transaction',
  FEEDBACK: '/feedback',
  SEARCH_PRODUCT: '/search-product',
  BROWSER_NOT_SUPPORTED: '/browser-not-supported',
  RTO: '/request-timeout',
  EMPTY_ORDER_CATEGORY: '/empty-order-category',
  OUTLET_CLOSE: '/outlet-close',
  REWARDS: '/rewards',
  MINIAPP_FAILURE: '/miniapp-failure',
  PAYMENT_RESULT: '/payment-result',
  PAYMENT_BILL: '/payment-bill',
  CANNOT_PAYMENT: '/cannot-payment',
  CANNOT_SICC: '/cannot-sicc',
};

export default URLS;
