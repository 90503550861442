import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import URLS from "@/URLS";
import { useStoreOutlet } from "@state/useStoreOutlet";
import { findDOMNode } from 'react-dom';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreSettings } from "@state/useStoreSettings";
import { useStoreOrderCategory } from "@state/useStoreOrderCategory";

export default function useCheckOpenCloseStore() {
    const history = useHistory();
    const { outlet } = useStoreOutlet(state => ({
        outlet: state.outlet
    }))

    const { settings } = useStoreSettings(state => ({
        settings: state.settings
    }))

    const { updateOrderCategory } = useStoreOrderCategory(state => ({
        updateOrderCategory: state.updateOrderCategory
    }))

    const checkOpenCLose = (param, check = false) => {
        let data = param.work_hours;
        let sumOpen = 0;
        let result = {
            disabled: true,
            currentOpen: ''
        };
        for (let i = 0; i < data.length; i++) {
            let time = moment();
            if (time.format('ddd').toLocaleLowerCase() === data[i].day || data[i].day === "everyday") {
                var currentTime = moment(time.format('HH:mm:ss'), 'HH:mm:ss');
                var open = moment(data[i].open, 'HH:mm:ss');
                var close = moment(data[i].close, 'HH:mm:ss');
                if (currentTime.isBetween(open, close)) {
                    sumOpen = sumOpen + 1;
                    param.selected = true;
                    data[i].selected = true;
                    result.disabled = false;
                    result.currentOpen = data[i];
                    break;
                } else {
                    result.currentOpen = data[i];
                }
            }
        }
        if (check) directCloseStore(param);
        return result
    };

    const directCloseStore = (type) => {
        const disabledButtons = Array.from(
            document.querySelectorAll('button[disabled]')
        ).map(findDOMNode);

        if (outlet.salestype.sales.length === disabledButtons.length) {
            history.replace(URLS.OUTLET_CLOSE);
        }
        if (outlet.salestype.sales.length - disabledButtons.length === 1 && type.selected) {
            let localType = { ...type };
        
            // KARENA IKON BELUM PERLU UNTUK DISIMPAN
            delete localType.icon;
            updateOrderCategory(localType)
        
            if (settings && settings.isDisplayCategory) {
              sendPostMessageToParent('path', URLS.CATEGORY);
              history.push(URLS.CATEGORY);
            } else {
              sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
              history.replace(URLS.MENU_CATALOGUE);
            }
        }

    }

    useEffect(() => {
        // directCloseStore();
        // eslint-disable-next-line
    }, []);

    return checkOpenCLose;

}
