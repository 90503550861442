import React, { useEffect, useRef } from 'react';
import './App.css';
import useSplashImage from '@hooks/SplashScreen/useSplashImage';
import InitiateRoutes from './InitiateRoutes';
import { clearStorageAfterScan } from '@data/localStorageSetter';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useStoreOutlet } from '@state/useStoreOutlet'
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreSiccMember } from '@state/useStoreSiccMember';
import RealmLoginFailurePage from '@pages/RealmLoginFailurePage';
import useHydration from '@hooks/useHydration';
import { useStoreAppColor } from '@state/useStoreAppColor';

export const getSearchParam = window.location.href.split('?')[1]
function App() {
  ///TODO: deteksi pertama scan/reload
  //cek semua state hydrated
  //error ke page lain
  const [hydration, kode, checkHydrated ]= useHydration();
  const check = Boolean(localStorage.getItem('reload_page'))

  const getSplashScreen = useSplashImage()
  const _refApp = useRef();

  const { clearCart } = useStoreCartItems(state => ({
    clearCart: state.clearCart
  }))
  const { clearSetting } = useStoreSettings(state => ({
    clearSetting: state.clearSetting
  }))
  const { clearOutlet } = useStoreOutlet(state => ({
    clearOutlet: state.clearOutlet
  }))
  const { clearSiccMember } = useStoreSiccMember(state => ({
    clearSiccMember: state.clearSiccMember
  }))
  const { updateAppColor } = useStoreAppColor(state => ({ 
    updateAppColor: state.updateAppColor
  }))


  useEffect(() => {
    if (getSearchParam) {

      clearStorageAfterScan();
      clearCart()
      clearSetting()
      clearOutlet()
      clearSiccMember()
      getSplashScreen("splash")

      const colorFromUrl = (parameter) => {
        return new URLSearchParams(window.location.href).get(parameter);
      };
      const constructColorApp = {
        primaryThemeColor: colorFromUrl('primary_theme') || '#FF8A37',
        secondaryThemeColor: colorFromUrl('secondary_theme') || '#FEEDE2',
        primaryFontColor: colorFromUrl('primary_text') || '#000000',
        secondaryFontColor: colorFromUrl('secondary_text') || '#ffffff',
        backgroundColor:  colorFromUrl('background') || '#FFE7D7',
        backgroundProduct: '#FFFFFF',
        headerAccordion: '#FF911C',
        successAccordion: '#00AA13',
        addButton: '#00AA13',
        backgroundAccordion: '#FFFFFF',
      };
      updateAppColor(constructColorApp);
    }
  }, [getSearchParam])

  useEffect(() => {
    console.log('check hydration-----',  check);
    if(check) {
      //check hydration
      checkHydrated();
    }

    window.onbeforeunload = function() {
      console.log('reload---------');
      localStorage.setItem('reload_page', true);
    };

    setTimeout(() => {
      localStorage.setItem('reload_page', false);
    }, 2000);
  }, [check])

  return (
    <div className="App" ref={_refApp}>
      {
        hydration ? 
        <InitiateRoutes />
        : <RealmLoginFailurePage message={`Failed to read stored data (${kode})!`}/>
      }
      {/* kode setiap mewakili zustand state hydrate sendiri2 */}
    </div>
  );

}

export default App;
