export const calculateSumTaxes = (oldTaxes, newTaxes) => {
    let sumTaxes = [];
    newTaxes.map((tax) => {
        let oldIndex = oldTaxes.findIndex((x) => x.TTax === tax.TTax);
        if (oldIndex !== -1) {
            oldTaxes[oldIndex].value = oldTaxes[oldIndex].value + tax.value;
            sumTaxes.push(oldTaxes[oldIndex]);
        } else {
            sumTaxes.push(tax);
        }
        return null;
    });

    return sumTaxes;
}