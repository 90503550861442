import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Backdrop, Modal, Typography, Alert, AlertTitle, Fade } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';


const ModalContent = styled('div')(({ theme }) => ({
    width: "70%",
    maxWidth: 500,
    height: "auto",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    background: "transparent",
    textAlign: 'center',
    outline: 'none',
    margin: 'auto', // Center horizontally
    position: 'absolute',
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Adjust position to ensure exact center
}));

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndex.drawer + 2,
        outline: 'none', // Remove default modal outline
    },
    modalContent: {
        position: 'relative',
        width: "50%",
        maxWidth: 500, // Limit the maximum width
        padding: theme.spacing(3),
        paddingTop: theme.spacing(6),
        backgroundColor: 'white',
        borderRadius: theme.shape.borderRadius, // Add rounded corners
        textAlign: 'center', // Center text content
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
})

const LostConnectionModal = (_props, ref) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false)
    const show = (flag) => {
        setVisible(flag);
    };


    useImperativeHandle(ref, () => ({ show }));
    return (
        <Modal
            aria-labelledby="lost-connection-modal"
            aria-describedby="resolving-lost-connection"
            open={visible}
            onClose={() => setVisible(false)}
            closeAfterTransition
            slots={{
                backdrop: Backdrop,
            }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={visible}>
                <ModalContent>
                    <Alert
                        icon={<WarningIcon fontSize="large" />}
                        severity="warning"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <AlertTitle>Lost Connection</AlertTitle>
                        <Typography sx={{ paddingBottom: 2 }} variant="body2">
                            Please check your internet connection!
                        </Typography>
                    </Alert>
                </ModalContent>
            </Fade>
        </Modal>
    );
}


export default forwardRef(LostConnectionModal)