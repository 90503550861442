import { sendErrorData } from '@functions/ErrorMessagesSender';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import URLS from '@/URLS';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreAppData } from '@state/useGlobalState';

export default function useCheckValidStore() {
    const history = useHistory();
    const { appData } = useStoreAppData(state => ({ appData: state.appData }))
    const { config } = appData;
    const { getUserLogin } = useStoreFirstCredential(state => ({
        getUserLogin: state.getUserLogin,
    }))
    const [valid, setValid] = useState(false);

    const fecthCheckValidStore = async () => {
        let result;
        const payload = config.tableID
        try {
            const currentUser = await getUserLogin();
            if (!config.tableID) return true;
            result = await currentUser.callFunction(
                'checkValidStore',
                payload,
            );
            return result;
        } catch (error) {
            sendErrorData({
                message: `${error.message} | ${result ? JSON.stringify(result.json()) : 'null result from server'}`,
                table: config.tableName,
                file: 'useCheckValidStore.js',
                func: 'fecthCheckValidStore()',
                payload: payload
            });
            return false;
        }
    }

    const checkValidStore = async () => {
        if (config.order_method === 'normal_order' || config.order_method === 'waiter_order') {
            let validStore = await fecthCheckValidStore();
            if (!validStore) {
                setValid(false);
                sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
                history.replace(URLS.CONNECTION_FAILURE, {
                    message: 'The selected table is unavailable at this store.'
                });
            }
        }
        setValid(true);
    }

    useEffect(() => {
        if (config && config !== undefined && config !== null) {
            checkValidStore();
        }
    }, [appData])

    return valid;
}
