import { getPriceBySellBand } from './getItemPriceBySellBand';

const sumPrice = (data, qty, sellBandId) => {
  let sumPrepItemPrice = 0;

  if (!sellBandId) {
    data.prep_items = data?.prep_items ? data.prep_items : [];

    let sumPrepItem = data.prep_items.reduce((prev, curr) => {
      prev += curr.baseQuantity * curr.amount * (qty || 1);
      return prev;
    }, 0);

    return sumPrepItem;
  }

  data.group = data.group 
              ? data?.group ? data.group : []
              : data?.prep_group ? data.prep_group : []
  
  let findPrepItem = data.group.reduce((prev, curr) => {
    if (curr.item && curr?.item?.length > 0) {
      prev.push(...curr.item);
    }
    if (curr.prep_item && curr?.prep_item?.length > 0) {
      prev.push(...curr.prep_item);
    }
    return prev;
  }, []);

  if (findPrepItem.length > 0) {
    findPrepItem.forEach((val) => {
      let getPrepItemPrice = getPriceBySellBand(val, sellBandId);

      if (val.checked && getPrepItemPrice > 0) {
        sumPrepItemPrice += getPrepItemPrice * (val.quantity || 1);
      }
    });
  }

  sumPrepItemPrice += getPriceBySellBand(data, sellBandId);
  return sumPrepItemPrice * (qty || 1);
};

export const sumTotalPriceWithPrepItem = (data, qty, sellBandId) => {
  let getData = data.is_variant
    ? data.variant_item.find((data) => data.checked)
    : data;

  if (getData) {
    return sumPrice(getData, qty, sellBandId);
  }
  return 0;
};
