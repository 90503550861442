import { useStoreOutlet } from "@state/useStoreOutlet";
import { useStoreOrderCategory } from "@state/useStoreOrderCategory";
import URLS from "@/URLS";
import { useStoreSettings } from "@state/useStoreSettings";
import { useEffect } from "react";
export default function useCheckSalesType() {
    const { updateOrderCategory } = useStoreOrderCategory(state => ({ updateOrderCategory: state.updateOrderCategory }));
    const { outlet, getSalesType, getActiveSalesType, updateOutlet } = useStoreOutlet(state => ({
        outlet: state.outlet,
        getSalesType: state.getSalesType,
        getActiveSalesType: state.getActiveSalesType,
        updateOutlet: state.updateOutlet,
    }))

    const { getSetting } = useStoreSettings(state => ({
        getSetting: state.getSetting
    }))


    const checkSalesType = () => {
        let constructOutlet;
        if (outlet && outlet.status && outlet.status === "closed") {
            constructOutlet = {
                out_operational_time_text: outlet.out_operational_time_text,
                status: outlet.status
            };
            
        } else {
            constructOutlet = {
                outletId: outlet.outletId,
                openingHours: outlet.openingHours,
                outletName: outlet.outletName,
                tableId: outlet.tableId,
                tableName: outlet.tableName,
                salestype: outlet.salestype,
                currency: outlet.currency,
                currencySymbol: outlet.currencySymbol,
                localeCode: outlet.localeCode,
                workHours: outlet.workHours,
                out_operational_time_text: outlet.out_operational_time_text,
                status: outlet.status
            };
        }

        const orderType = outlet.tableId ? getSetting().qrTableOrderType : getSetting().qrStoreOrderType;
        const salestype = orderType ? outlet.salestype.sales.filter((e) => e.id === orderType) : orderType;

        if (salestype && getSetting().orderMethod !== "quick_order" && getSetting().useSiccMember) {
            constructOutlet.salestype.sales = salestype;
            updateOutlet(constructOutlet);
            updateOrderCategory(salestype[0]);
            if (getSetting().isDisplayCategory) {
                return URLS.CATEGORY;
            } else {
                return URLS.MENU_CATALOGUE;
            }
        } else {
            if (getSalesType().active === 1) {
                let localType = getActiveSalesType();
                // KARENA IKON BELUM PERLU UNTUK DISIMPAN
                delete localType.icon;
                updateOrderCategory(localType);
                if (getSetting().isDisplayCategory) {
                    return URLS.CATEGORY;
                } else {
                    return URLS.MENU_CATALOGUE;
                }
            } else {
                return URLS.ORDER_CATEGORY;
            }
        }
    }

    // useEffect(() => {
    //     checkSalesType()
    // }, [outlet])

    return checkSalesType
};