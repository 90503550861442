import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    maxWidth: 450,
    width: '100%',
    margin: 'auto'
  },
});

const RealmLoginFailurePage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState('QR Code is not valid');

  ///pesan this store does not have selected table!
  // TODO : Remove useeffect ketika sudah tidak digunakan
  useEffect(() => {
    const state = history?.location?.state;
    if ((state && state.message) || props.message) {
      setMessage(state ? state.message : props.message)
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={classes.container}>
      <Typography variant="h5" sx={{ textAlign: 'center', textTransform: 'capitalize', fontWeight: "bold" }}>
        {message}
      </Typography>
      <h3 style={{ textAlign: 'center' }}>
        Please re-scan QR code or request new QR
      </h3>
    </Box>
  );
};

export default RealmLoginFailurePage;
