import { insertPrepItems } from "./helper";

export const getItem = (item, key) => {
    let data = item.is_variant
        ? item.variant_item.find((data) => data.checked)
        : item;

    if (data) {
        if (key && key === 'name') {
            return { "plu_name": data.plu_name, "uuid_product": data.uuid_product };
        }

        if (
            (data.group && data.group.length > 0) ||
            data.additional_request ||
            (data.sub_product && data.sub_product.length > 0)
        ) {
            return insertPrepItems(data.group);
        }

        if (
            (data.prep_group && data.prep_group.length > 0) ||
            data.additional_request ||
            (data.sub_product && data.sub_product.length > 0)
        ) {
            return insertPrepItems(data.prep_group);
        }

    }
};