import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';

const useStyles = makeStyles({
  btnPicupTime: {
    textTransform: 'capitalize',
  },
  inputCustom: {
    width: '53%',
    maxWidth: '242px !important',
    left: '50% !important',
    transform: 'translateX(-50%) !important',
  },
  modalStyle: {
    backgroundColor: props => props.backgroundColor,
  },
  modalTitle: {
    backgroundColor: props => props.primaryThemeColor,
    height: theme.spacing(13),
    color: props => props.secondaryFontColor,
  },
  innerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  iconButton: {
    padding: theme.spacing(1),
    width: 65,
    justifyContent: 'flex-start',
  },
  removeButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '95%',
    margin: '12px auto',
  },
  innerContentContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      4,
    )}px`,
  },
  borderingGrid: {
    paddingBottom: theme.spacing(4),
    borderBottom: '1px solid #464646',
  },
  typeSales: {
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    '& .MuiFormLabel-root': {
      marginBottom: 5,
      fontSize: 12,
    }
  },
  typeSalesTitle: {
    display: 'inline-block',
    minWidth: '60%',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 4,
    backgroundColor: props => props.backgroundColor,
    boxShadow: '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '0'
    }
  },
  cartItemContainer: {
    padding: `${theme.spacing(3)}px 0`,
  },
  cartItem: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  footer: {
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px 0`,
  },
  buttonConfirm: {
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    width: '90%',
  },
  modifierText: {
    // ATAS PERMINTAAN PAK HENDRO, WARNA INI CUSTOM
    color: '#484848',
  },
  expandButtonModifier: {
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
  },
  textWrapper: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  buttonQtyCart: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
  },
  buttonQtyPrepItem: {
    width: 125,
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default useStyles;
